import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

export const Contact = () => {
  return (
    <>
      <Header />
      Contact
      <Footer />
    </>
  );
};

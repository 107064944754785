import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography } from "@mui/material";

export const About = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <br />
        <br />
        <Typography variant="h3" textAlign="center">
          <b>About Us</b>
        </Typography>
        <br />
        <br />
        <Typography>
          Uff Yeh is a hindi news channel in India. Uffyeh.com is a growing
          hindi news website that focuses on delivering latest national and
          international news www.hindpharma.com about business, politics,
          sports, entertainment, technology, lifestyle, astrology and more in
          hindi language.
        </Typography>
        <br />
        <Typography>
          Uff Yeh is a hindi news channel in India. Uffyeh.com is a growing
          hindi news website that focuses on delivering latest national and
          international news www.hindpharma.com about business, politics,
          sports, entertainment, technology, lifestyle, astrology and more in
          hindi language.
        </Typography>
        <br />
        <Typography>
          Uff Yeh is a hindi news channel in India. Uffyeh.com is a growing
          hindi news website that focuses on delivering latest national and
          international news www.hindpharma.com about business, politics,
          sports, entertainment, technology, lifestyle, astrology and more in
          hindi language.
        </Typography>
        <br />
        <Typography>
          Uff Yeh is a hindi news channel in India. Uffyeh.com is a growing
          hindi news website that focuses on delivering latest national and
          international news www.hindpharma.com about business, politics,
          sports, entertainment, technology, lifestyle, astrology and more in
          hindi language.
        </Typography>
        <br />
        <Typography>
          Uff Yeh is a hindi news channel in India. Uffyeh.com is a growing
          hindi news website that focuses on delivering latest national and
          international news www.hindpharma.com about business, politics,
          sports, entertainment, technology, lifestyle, astrology and more in
          hindi language.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

import { Navigation } from "swiper";
import { useEffect, useState } from "react";
import data from "../../data/ImageData.json";
import playerData from "../../data/allData.json";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useParams, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Container, Typography } from "@mui/material";

export const Player = () => {
  const { videoId } = useParams();
  const [obj, setObj] = useState({});

  const getData = () => {
    playerData.filter((e) => e.id === videoId).map((e) => setObj(e));
  };

  useEffect(() => {
    getData();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [videoId]);

  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <iframe src={`https://www.youtube.com/embed/01TediL_Xkg`}></iframe>
      </Container>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h3">
          <b>Bindass Uff</b>
        </Typography>
        <br />
        <br />
        <Typography>Hindi || News</Typography>
        <br />
        <Typography>
          Uff Yeh is a hindi news channel in India. Uffyeh.com is a growing
          hindi news website that focuses on delivering latest national and
          international news www.hindpharma.com about business, politics,
          sports, entertainment, technology, lifestyle, astrology and more in
          hindi language.
        </Typography>
      </Container>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h5">Recommended Shows</Typography>
        <br />
        <Swiper modules={[Navigation]} slidesPerView={5} navigation>
          {data.cards.map((e, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={`/player/${e.id}`}>
                  <Box sx={{ p: 1 }}>
                    <img src={e.image} alt="Card" />
                  </Box>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <Footer />
    </>
  );
};
